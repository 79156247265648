<template>
	<div class="bardian" v-title="'软装搭配——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<NavBar></NavBar>
		<div class="box">
			<img
				class="bran"
				src="../image/decoration/coll_header_pc.jpg"
				alt=""
			/>
		</div>
        <div class="box-mob">
            <img
				class="bran"
				src="../image/decoration/coll_header_mob.jpg"
				alt=""
			/>
		</div>
		<div class="content f-y-s-c">
			<div class="box1">
				<div class="box1-content ">
					<div class="list f-x-b-c">
						<div class="list-item f-y-c-c">
							<div class="car f-x-c-c">
								<img
									class="img"
									src="../image/decoration/coll_box1_1.png"
									alt=""
								/>
							</div>
							<span class="text1">生活方式</span>
						</div>
						<div class="list-item f-y-c-c">
							<div class="car f-x-c-c">
								<img
									class="img"
									src="../image/decoration/coll_box1_2.png"
									alt=""
								/>
							</div>
							<span class="text1">整体环境</span>
						</div>
						<div class="list-item f-y-c-c">
							<div class="car f-x-c-c">
								<img
									class="img"
									src="../image/decoration/coll_box1_3.png"
									alt=""
								/>
							</div>
							<span class="text1">空间美学</span>
						</div>
						<div class="list-item f-y-c-c">
							<div class="car f-x-c-c">
								<img
									class="img"
									src="../image/decoration/coll_box1_4.png"
									alt=""
								/>
							</div>
							<span class="text1">陈设艺术</span>
						</div>
						<div class="list-item f-y-c-c">
							<div class="car f-x-c-c">
								<img
									class="img"
									src="../image/decoration/coll_box1_5.png"
									alt=""
								/>
							</div>
							<span class="text1">材质风格</span>
						</div>
						<div class="list-item f-y-c-c">
							<div class="car f-x-c-c">
								<img
									class="img"
									src="../image/decoration/coll_box1_6.png"
									alt=""
								/>
							</div>
							<span class="text1">生活功能</span>
						</div>
						<div class="list-item f-y-c-c">
							<div class="car f-x-c-c">
								<img
									class="img"
									src="../image/decoration/coll_box1_7.png"
									alt=""
								/>
							</div>
							<span class="text1">意境体验</span>
						</div>
						<div class="list-item f-y-c-c">
							<div class="car f-x-c-c">
								<img
									class="img"
									src="../image/decoration/coll_box1_8.png"
									alt=""
								/>
							</div>
							<span class="text1">个性喜好</span>
						</div>
					</div>
                    <div class="form-box">
                        <VisitorForm4 
                            :showAddress="false"
                            :showArea="false"
                            :showHouseType="false"
                            title="专业软装设计服务" 
                            desc="打造高端私人空间"
                            adUnitCode="tyui">
                        </VisitorForm4>
                    </div>
				</div>
			</div>
			<div class="box2">
				<div class="header f-y-c-c">
					<span class="text1">软装搭配元素</span>
					<span class="text2">链接艺术与生活的桥梁</span>
				</div>
				<div class="box2-content f-y-c-c">
					<div class="tab-list f-x-b-c">
						<div
							v-for="(item, index) in tabsList"
							:key="index"
							@click="changeTabs(index)"
							class="list-item f-x-c-s"
							:class="index == curTab ? 'tab-active' : ''"
						>
							{{ item.label }}
						</div>
					</div>
					<div class="content-box f-x-b-c">
						<img
							:src="tabsList[curTab].url"
							alt=""
							class="img-box"
						/>
						<div class="info f-x-c-c">
							<span class="text3">{{
								tabsList[curTab].label
							}}</span>
							<div class="line"></div>
							<p class="text4">{{ tabsList[curTab].desc }}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="box3">
				<div class="header f-y-c-c">
					<span class="text1">赋能生活方式</span>
					<span class="text2">向心而生的独特方式</span>
				</div>
				<div class="box3-content f-y-c-c">
					<div class="tab-list f-x-b-c">
						<div
							v-for="(item, index) in tabsList2"
							:key="index"
							@click="changeTabs2(index)"
							class="list-item f-y-c-c"
							:class="index == curTab2 ? 'tab-active' : ''"
						>
							<img
								v-if="curTab2 == index"
								class="icon"
								:src="item.iconHoverUrl"
								alt=""
							/>
							<img
								v-else
								class="icon"
								:src="item.iconUrl"
								alt=""
							/>
							<span class="text3">{{ item.label }}</span>
						</div>
					</div>
					<div ref="refBox" class="content-box f-x-b-c">
						<splitpanes
							class="default-theme f-x-c-c"
							@resize="paneSize = $event[0].size"
							style="height: 100%; width: 100%"
						>
							<pane min-size="15" :size="paneSize">
								<img
									ref="refLeft"
									:src="tabsList2[curTab2].befUrl"
									alt=""
									class="bef-img"
								/>
							</pane>
							<pane min-size="15" :size="100 - paneSize">
								<img
									ref="refRight"
									:src="tabsList2[curTab2].aftUrl"
									alt=""
									class="aft-img"
								/>
							</pane>
						</splitpanes>
						<div class="btn1 btn f-x-c-c">前</div>
						<div class="btn2 btn f-x-c-c">后</div>
					</div>
				</div>
			</div>
			<div class="box4">
                <VisitorForm2
                    title="软装定制方案"
                    desc="一站式安装拎包即入住服务"
                    :showArea="false"
                    :showHouseType="false"
                    :showCity="false"
                    adUnitCode="tyui"
                ></VisitorForm2>
			</div>
			<div class="box5 f-y-c-c">
				<div class="header f-y-c-c">
					<span class="text1">全球严选好物</span>
					<span class="text2">品质生活，好而不贵</span>
				</div>
				<div class="box5-content f-y-s-c">
					<div class="list1">
						<img
							v-for="(item, index) in brandList"
							:key="index"
							:src="item.path"
							alt=""
							class="item"
						/>
					</div>
					<div class="btn f-x-c-c">软装定制 · 服务流程</div>
					<div class="list2 f-x-b-c">
						<div class="list-item f-y-s-c">
							<div class="img-box">
								<img
									class="icon"
									src="../image/decoration/coll_box5_icon.png"
									alt=""
								/>
								<span class="seq">01</span>
								<img
									src="../image/decoration/coll_box5_1.jpg"
									alt=""
									class="img"
								/>
							</div>

							<div class="text3 f-x-c-c">现场咨询</div>
						</div>
						<div class="list-item f-y-s-c">
							<div class="img-box">
								<img
									class="icon"
									src="../image/decoration/coll_box5_icon.png"
									alt=""
								/>
								<span class="seq">02</span>
								<img
									src="../image/decoration/coll_box5_2.jpg"
									alt=""
									class="img"
								/>
							</div>
							<div class="text3 f-x-c-c">现场测量</div>
						</div>
						<div class="list-item f-y-s-c">
							<div class="img-box">
								<img
									class="icon"
									src="../image/decoration/coll_box5_icon.png"
									alt=""
								/>
								<span class="seq">03</span>
								<img
									src="../image/decoration/coll_box5_3.jpg"
									alt=""
									class="img"
								/>
							</div>

							<div class="text3 f-x-c-c">初步方案</div>
						</div>
						<div class="list-item f-y-s-c">
							<div class="img-box">
								<img
									class="icon"
									src="../image/decoration/coll_box5_icon.png"
									alt=""
								/>
								<span class="seq">04</span>
								<img
									src="../image/decoration/coll_box5_4.jpg"
									alt=""
									class="img"
								/>
							</div>

							<div class="text3 f-x-c-c">产品体验</div>
						</div>
						<div class="list-item f-y-s-c">
							<div class="img-box">
								<img
									class="icon"
									src="../image/decoration/coll_box5_icon.png"
									alt=""
								/>
								<span class="seq">05</span>
								<img
									src="../image/decoration/coll_box5_5.jpg"
									alt=""
									class="img"
								/>
							</div>

							<div class="text3 f-x-c-c">深化方案</div>
						</div>
						<div class="list-item f-y-s-c">
							<div class="img-box">
								<img
									class="icon"
									src="../image/decoration/coll_box5_icon.png"
									alt=""
								/>
								<span class="seq">06</span>
								<img
									src="../image/decoration/coll_box5_6.jpg"
									alt=""
									class="img"
								/>
							</div>

							<div class="text3 f-x-c-c">产品选定</div>
						</div>
						<div class="list-item f-y-s-c">
							<div class="img-box">
								<img
									class="icon"
									src="../image/decoration/coll_box5_icon.png"
									alt=""
								/>
								<span class="seq">07</span>
								<img
									src="../image/decoration/coll_box5_7.jpg"
									alt=""
									class="img"
								/>
							</div>

							<div class="text3 f-x-c-c">现场摆场</div>
						</div>
						<div class="list-item f-y-s-c">
							<div class="img-box">
								<img
									class="icon"
									src="../image/decoration/coll_box5_icon.png"
									alt=""
								/>
								<span class="seq">08</span>
								<img
									src="../image/decoration/coll_box5_8.jpg"
									alt=""
									class="img"
								/>
							</div>
							<div class="text3 f-x-c-c">售后服务</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 底部 -->
		<Footer></Footer>

        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import VisitorForm2 from '../components/VisitorForm2.vue'
import VisitorForm4  from '../components/VisitorForm4.vue'
import { postmobile } from '@/api/views/name.js'
import { Splitpanes, Pane } from 'splitpanes'
export default {
	components: {
		NavBar,
		Footer,
		VisitorForm2,
		Splitpanes,
		Pane,
        VisitorForm4
	},

	data() {
		return {
			dragging: false,
			initialMouseX: 0,
			initialLeft: null,

			tabsList: [
				{
					value: 0,
					label: '家具',
					desc: '一个家庭的家居环境与生活方式，现代家具主要分板式家具和实木家具',
					url: require('../image/decoration/coll_box2_1.jpg'),
				},
				{
					value: 1,
					label: '灯饰',
					desc: '由单一的实用性变为实用和装饰性相结合灯饰主要有现代灯、欧式灯、美式灯、中式灯等',
					url: require('../image/decoration/coll_box2_2.jpg'),
				},
				{
					value: 2,
					label: '布艺',
					desc: '赋予居室独居个性的格调不同产品可以搭配出很明显的空间风格',
					url: require('../image/decoration/coll_box2_3.jpg'),
				},
				{
					value: 3,
					label: '地毯',
					desc: '历史悠久的传统工艺美术品类之一，多以棉、麻、毛、丝、草纱线等天然纤维或化学合成纤维制成',
					url: require('../image/decoration/coll_box2_4.jpg'),
				},
				{
					value: 4,
					label: '饰品',
					desc: '营造家居氛围的点睛之笔工艺品、纺织品、收藏品、灯具、花艺、植物等',
					url: require('../image/decoration/coll_box2_5.jpg'),
				},
				{
					value: 5,
					label: '收藏品',
					desc: '体现屋主的艺术品位，名人字画、陶瓷、玉器、碑扁、织锦等',
					url: require('../image/decoration/coll_box2_6.jpg'),
				},
			],
			curTab2: 0,
			paneSize: '50',
			tabsList2: [
				{
					value: 0,
					label: '客 厅',
					befUrl: require('../image/decoration/coll_box3_bef1.jpg'),
					aftUrl: require('../image/decoration/coll_box3_aft1.jpg'),
					iconUrl: require('../image/decoration/coll_box3_icon1.png'),
					iconHoverUrl: require('../image/decoration/coll_box3_icon1_hover.png'),
				},
				{
					value: 1,
					label: '卧 室',
					befUrl: require('../image/decoration/coll_box3_bef2.jpg'),
					aftUrl: require('../image/decoration/coll_box3_aft2.jpg'),
					iconUrl: require('../image/decoration/coll_box3_icon2.png'),
					iconHoverUrl: require('../image/decoration/coll_box3_icon2_hover.png'),
				},
				{
					value: 2,
					label: '卫生间',
					befUrl: require('../image/decoration/coll_box3_bef3.jpg'),
					aftUrl: require('../image/decoration/coll_box3_aft3.jpg'),
					iconUrl: require('../image/decoration/coll_box3_icon3.png'),
					iconHoverUrl: require('../image/decoration/coll_box3_icon3_hover.png'),
				},
				{
					value: 3,
					label: '书 房',
					befUrl: require('../image/decoration/coll_box3_bef4.jpg'),
					aftUrl: require('../image/decoration/coll_box3_aft4.jpg'),
					iconUrl: require('../image/decoration/coll_box3_icon4.png'),
					iconHoverUrl: require('../image/decoration/coll_box3_icon4_hover.png'),
				},
				{
					value: 4,
					label: '餐 厅',
					befUrl: require('../image/decoration/coll_box3_bef5.jpg'),
					aftUrl: require('../image/decoration/coll_box3_aft5.jpg'),
					iconUrl: require('../image/decoration/coll_box3_icon5.png'),
					iconHoverUrl: require('../image/decoration/coll_box3_icon5_hover.png'),
				},
			],
			brandList: [
				{
					path: require('../image/common/brand1.jpg'),
				},
				{
					path: require('../image/common/brand2.jpg'),
				},
				{
					path: require('../image/common/brand3.jpg'),
				},
				{
					path: require('../image/common/brand4.jpg'),
				},
				{
					path: require('../image/common/brand5.jpg'),
				},
				{
					path: require('../image/common/brand6.jpg'),
				},
				{
					path: require('../image/common/brand7.jpg'),
				},
				{
					path: require('../image/common/brand8.jpg'),
				},
				{
					path: require('../image/common/brand9.jpg'),
				},
				{
					path: require('../image/common/brand10.jpg'),
				},
				{
					path: require('../image/common/brand11.jpg'),
				},
				{
					path: require('../image/common/brand12.jpg'),
				},
				{
					path: require('../image/common/brand13.jpg'),
				},
				{
					path: require('../image/common/brand14.jpg'),
				},
				{
					path: require('../image/common/brand15.jpg'),
				},
				// {
				// 	path: require('../image/common/brand16.jpg'),
				// },
				// {
				// 	path: require('../image/common/brand17.jpg'),
				// },
				// {
				// 	path: require('../image/common/brand18.jpg'),
				// },
				// {
				// 	path: require('../image/common/brand19.jpg'),
				// },
				// {
				// 	path: require('../image/common/brand20.jpg'),
				// },
			],
			curTab: 0,
		}
	},
	created() {},
	async mounted() {
		this.changeTabs(1)
	},
	methods: {
		changeTabs(index) {
			this.curTab = index
		},
		changeTabs2(index) {
			this.curTab2 = index
            this.paneSize = 50
		},
	},
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {

    .bardian {
        overflow-x: hidden;
		.box {
            display: none;
		}
        .box-mob{
            width: 100%;
			height: 200px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
        }
        .content {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #ffffff;

			.box1 {
				width:calc(100% - 24px) ;
				// height: 300px;
				box-sizing: border-box;
				margin: 50px 12px 0 12px;
				// padding: 0 10px;
				.box1-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-flow: column;
					padding: 20px 10px;
					box-sizing: border-box;
					width: 100%;
					height: 100%;
					background:no-repeat center/cover url(../image/decoration/coll_box1_bg.jpg);
					// background-size: 100% 100%;
					// background-repeat: no-repeat;
					border-radius: 16px;
                    flex-direction: column-reverse;
					// .list:nth-child(-n+4){
					//         margin-top: 30px;
					//     }
					.list {
                        width: 90%;
                        margin-top: 20px;
						flex-wrap: wrap;
						.list-item:nth-child(n + 5) {
							margin-top: 10px;
						}
						.list-item {
							width: 70px;
							height: 90px;
							background: #753cbe;
							border-radius: 8px;

							.car {
								width: 45px;
								height: 45px;
								background: #ffffff;
								border-radius: 50%;
								.img {
									width: 30px;
									height: 30px;
								}
							}
							.text1 {
								
								font-weight: 500;
								font-size: 12px;
								color: #ffffff;
								line-height: 12px;
								margin-top: 5px;
							}
						}
					}
				}
                .form-box{
                    width: 90%;
                    height: 250px;
                    
                }
			}
			.box2 {
				margin-top: 50px;
				width: 100%;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box2-content {
					width: 100%;
					// height: 1200px;
					background:no-repeat center/cover url(../image/decoration/coll_box2_bg.jpg);
                    padding-bottom: 30px;
					.tab-list {
                        margin-top: 10px;
						width: 100%;
                        box-sizing: border-box;
                        padding:0 15px;
						.tab-active {
							// height: 89px !important;
							border-bottom: 3px solid #753cbe;
						}
						.list-item {
							cursor: pointer;
							padding: 0 5px;
							height: 40px;
							
							font-weight: bold;
							font-size: 16px;
							color: #444444;
							box-sizing: border-box;
						}
					}
					.content-box {
						width: 92%;
						height: 250px;
						background: #ffffff;
						box-shadow: 0px 3px 24px 0px rgba(4, 0, 0, 0.08);
						padding: 10px;
						box-sizing: border-box;
						position: relative;
						.img-box {
							width: 100%;
							height: 100%;
						}
						.info {
							position: absolute;
							padding: 0 10px;
							bottom: 30px;
							left: calc((100% - 300px)/2);
							width: 300px;
							height: 60px;
							background: #fefefe;
							border-radius: 5px;
							opacity: 0.98;
							box-sizing: border-box;
							.text3 {
								
								font-weight: bold;
								font-size: 20px;
								color: #753cbe;
								line-height: 20px;
								white-space: nowrap;
							}
							.line {
								width: 1px;
								height: 20px;
								background: #e5e5e5;
								margin: 0 15px;
							}
							.text4 {
								
								font-weight: 400;
								font-size: 12px;
								color: #120523;
								line-height: 15px;
							}
						}
					}
				}
			}
			.box3 {
				background: #fff;
				width: 100%;
				margin-top: 50px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box3-content {
					width: 100%;
					margin-top: 20px;
					// padding: 0 180px;
					box-sizing: border-box;
					.tab-list {
						z-index: 9;
						width: 100%;
                        padding: 0 40px;
						height: 50px;
                        box-sizing: border-box;
						// box-shadow: 0px 3px 24px 0px rgba(4, 0, 0, 0.08);
						// border-radius: 8px;
						// overflow: hidden;
						.tab-active {
							background: #753cbe !important;
							.text3 {
								color: #fff !important;
							}
						}
                        .list-item:first-child{
                            border-radius: 8px 0 0 8px;
                        }
                        .list-item:last-child{
                            border-radius:0 8px 8px  0 ;
                        }
						.list-item {
							cursor: pointer;
							width: 60px;
							height: 50px;
							background: #fff;
							.icon {
								width: 20px;
								height: 20px;
							}
							.text3 {
								// margin-top: 3px;
								
								font-weight: 500;
								font-size: 13px;
								color: #000;
								line-height: 13px;
                                margin-top: 3px;
							}
						}
					}
					.content-box {
						width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box;
						height: 220px;
						border-radius: 15px;
						position: relative;
						overflow: hidden;
						margin-top: -20px;
                        /deep/ .splitpanes__splitter{
                            height: 100%;
                            width: 2px;
                        }
                        /deep/.splitpanes__splitter:before {
                            content: '';
                            width: 20px;
                            height: 20px;
                            position: absolute;
                            top: initial;
                            left: -8px;
                            bottom: 10px;
                            transition: opacity 0.4s;
                            background:center/cover url(../image/decoration/coll_box3_btn.png);
                            // background-color: rgba(255, 0, 0, 0.3);
                            z-index: 9;
                        }
                        /deep/.splitpanes__splitter:after {
                            content: '滑动试试';
                            width: 60px;
                            height: 10px;
                            text-align: center;
                            position: absolute;
                            top: initial;
                            left: -30px;
                            bottom: 3px;
                            z-index: 9;
                            
                            font-weight: 500;
                            font-size: 10px;
                            color: #ffffff;
                            background-color: initial;
                        }
						.bef-img {
							// width: 777px;
							height: 100%;
							object-fit: cover;
						}
						.line {
							// width: 2px;
							position: absolute;
							top: 0;
							left: 187px;
							height: 280px;
							border: 1px dashed #ffffff;
						}
						.aft-img {
							// width: 777px;
							height: 100%;
							object-fit: cover;
						}
						.btn3 {
							width: 60px;
							cursor: pointer;
							position: absolute;
							bottom: 5px;
							left: 157px;
							// left: 0px;
							.img {
								width: 20px;
								height: 20px;
								// background: #FFFFFF;
								border-radius: 50%;
								// opacity: 0.5;
							}
							.text1 {
								
								font-weight: 500;
								font-size: 10px;
								color: #ffffff;
								margin-top: 3px;
							}
						}
						.btn {
							position: absolute;
							width: 25px;
							height: 25px;
							// background: #000;
							border-radius: 50%;
							background: rgba(255, 255, 255, 0.5);
							border: 5px solid rgba(0, 0, 0, 0.5);
							bottom: 15px;
							
							font-weight: 500;
							font-size: 13px;
							color: #000;
						}
						.btn1 {
							left: 15px;
						}
						.btn2 {
							right: 15px;
						}
					}
				}
			}
			.box4 {
				width: 100%;
				// height: 100px;
				margin-top: 50px;
				padding: 0 25px;
				box-sizing: border-box;
			}
			.box5 {
				background: #fff;
				width: 100%;
				margin-top: 50px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box5-content {
					width: 100%;
					// height: 1800px;
					margin-top: 20px;
					padding: 0 10px 40px 10px;
					box-sizing: border-box;
					background:no-repeat center/cover url(../image/decoration/coll_box5_bg.jpg);
					.list1 {
						padding: 0 10px 20px 10px;
						box-sizing: border-box;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
                        align-items: center;
						background: #fff;
						width: 100%;
						// height: 480px;
						background: #ffffff;
						border-radius: 0px 0px 8px 8px;
						.item {
							width: 100px;
							height: 40px;
							background: #ffffff;
							border: 1px solid #e7e7e7;
							margin-bottom: 5px;
						}
					}
					.btn {
						width: 160px;
						height: 30px;
						background: #753cbe;
						border-radius: 20px;
						margin-top: 20px;
						
						font-weight: bold;
						font-size: 15px;
						color: #ffffff;
						z-index: 9;
					}
					.list2 {
						padding: 20px 5px  0 5px;
						box-sizing: border-box;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						margin-top: -15px;
						background: #fff;
						width: 100%;
						// height: 790px;
						background: #ffffff;
						border-radius: 10px;
						.list-item {
							width: 80px;
							// height: 70px;
							border-radius: 4px;
							background: #ffffff;
							margin-bottom: 15px;
							.img-box {
								width: 100%;
								height: 70px;
								position: relative;
                                border-radius: 4px;
                                box-shadow: 0px 3px 6px 0px rgba(4, 0, 0, 0.4);
								.icon {
									position: absolute;
									left: 0;
									top: 0;
									width: 35px;
									height: 35px;
								}
								.seq {
									position: absolute;
									left: 1px;
									top: 1px;
									font-family: DIN;
									font-weight: bold;
									font-size: 8px;
									color: #ffffff;
								}
								.img {
									width: 100%;
									height: 70px;
									object-fit: cover;
									border-radius: 4px;
								}
							}

							.text3 {
								width: 60px;
								height: 20px;
								background: #ffffff;
								border-radius: 10px;
								opacity: 0.98;
								
								font-weight: 500;
								font-size: 10px;
								color: #120523;
								margin-top: -10px;
								box-shadow: 0px 3px 6px 0px rgba(4, 0, 0, 0.04);
							}
						}
					}
				}
			}
		}
    }
}
@media screen and (min-width: 900px) {
	.draggable {
		position: absolute;
		width: 100px;
		height: 50px;
		background-color: #ff0101;
		cursor: pointer;
		top: 200px;
		z-index: 99;
	}
	.bardian {
		.box {
			width: 100%;
			height: 680px;
			// margin-top: 96px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
        .box-mob{
            display: none;
        }
		.content {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #ffffff;

			.box1 {
				width: 100%;
				height: 686px;
				box-sizing: border-box;
				margin-top: 130px;
				padding: 0 180px;
				.box1-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
					padding: 0 60px;
					box-sizing: border-box;
					width: 100%;
					height: 100%;
					background: url(../image/decoration/coll_box1_bg.jpg);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					border-radius: 26px;
					// .list:nth-child(-n+4){
					//         margin-top: 30px;
					//     }
					.list {
						flex-wrap: wrap;
						.list-item:nth-child(n + 5) {
							margin-top: 30px;
						}
						.list-item {
							width: 200px;
							height: 220px;
							background: #753cbe;
							border-radius: 20px;

							.car {
								width: 130px;
								height: 130px;
								background: #ffffff;
								border-radius: 50%;
								.img {
									width: 71px;
									height: 63px;
								}
							}
							.text1 {
								
								font-weight: 500;
								font-size: 30px;
								color: #ffffff;
								line-height: 30px;
								margin-top: 20px;
							}
						}
					}
				}
                .form-box{
                    width: 480px;
                    height: 550px;
                    min-width: 480px;
                    margin-left: 50px;
                }
			}
			.box2 {
				margin-top: 130px;
				width: 100%;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
					.text3 {
						margin-top: 20px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box2-content {
					width: 100%;
					height: 1200px;
					background: url(../image/decoration/coll_box2_bg.jpg);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					.tab-list {
						width: 1055px;

						.tab-active {
							// height: 89px !important;
							border-bottom: 10px solid #753cbe;
						}
						.list-item {
							cursor: pointer;
							padding: 0 10px;
							height: 100px;
							
							font-weight: bold;
							font-size: 48px;
							color: #444444;
							box-sizing: border-box;
						}
					}
					.content-box {
						width: 1554px;
						height: 960px;
						background: #ffffff;
						box-shadow: 0px 3px 24px 0px rgba(4, 0, 0, 0.08);
						padding: 36px;
						box-sizing: border-box;
						position: relative;
						.img-box {
							width: 100%;
							height: 100%;
						}
						.info {
							position: absolute;
							padding: 0 40px;
							bottom: 145px;
							left: 257px;
							width: 1040px;
							height: 173px;
							background: #fefefe;
							border-radius: 13px;
							opacity: 0.98;
							box-sizing: border-box;
							.text3 {
								
								font-weight: bold;
								font-size: 64px;
								color: #753cbe;
								line-height: 64px;
								white-space: nowrap;
							}
							.line {
								width: 2px;
								height: 80px;
								background: #e5e5e5;
								margin: 0 40px;
							}
							.text4 {
								
								font-weight: 400;
								font-size: 32px;
								color: #120523;
								line-height: 48px;
							}
						}
					}
				}
			}
			.box3 {
				background: #fff;
				width: 100%;
				margin-top: 130px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box3-content {
					width: 100%;
					margin-top: 40px;
					padding: 0 180px;
					box-sizing: border-box;
					.tab-list {
						z-index: 9;

						width: 1000px;
						height: 150px;
						background: #ffffff;
						box-shadow: 0px 3px 24px 0px rgba(4, 0, 0, 0.08);
						border-radius: 19px;
						overflow: hidden;
						.tab-active {
							background: #753cbe !important;
							.text3 {
								color: #fff !important;
							}
						}
						.list-item {
							cursor: pointer;
							width: 200px;
							height: 150px;
							background: #fff;
							.icon {
								width: 57px;
								height: 57px;
							}
							.text3 {
								margin-top: 19px;
								
								font-weight: 500;
								font-size: 32px;
								color: #000;
								line-height: 32px;
							}
						}
					}
					.content-box {
						width: 1554px;
						height: 972px;
						border-radius: 30px;
						position: relative;
						overflow: hidden;
						margin-top: -75px;
                        /deep/ .splitpanes__splitter{
                            height: 100%;
                        }
                        /deep/.splitpanes__splitter:before {
                            content: '';
                            width: 64px;
                            height: 64px;
                            position: absolute;
                            top: initial;
                            left: -29px;
                            bottom: 22px;
                            transition: opacity 0.4s;
                            background:url(../image/decoration/coll_box3_btn.png);
                            // background-color: rgba(255, 0, 0, 0.3);
                            z-index: 9;
                        }
                        /deep/.splitpanes__splitter:after {
                            content: '滑动试试';
                            width: 120px;
                            height: 20px;
                            position: absolute;
                            top: initial;
                            left: -57px;
                            bottom: 22px;
                            z-index: 9;
                            
                            font-weight: 500;
                            font-size: 30px;
                            color: #ffffff;
                            background-color: initial;
                        }
						.bef-img {
							// width: 777px;
							height: 100%;
							object-fit: cover;
						}
						.line {
							// width: 2px;
							position: absolute;
							top: 0;
							left: 776px;
							height: 972px;
							border: 2px dashed #ffffff;
						}
						.aft-img {
							// width: 777px;
							height: 100%;
							object-fit: cover;
						}
						.btn3 {
							width: 120px;
							cursor: pointer;
							position: absolute;
							bottom: 20px;
							left: 717px;
							// left: 0px;
							.img {
								width: 64px;
								height: 64px;
								// background: #FFFFFF;
								border-radius: 50%;
								// opacity: 0.5;
							}
							.text1 {
								
								font-weight: 500;
								font-size: 30px;
								color: #ffffff;
								margin-top: 8px;
							}
						}
						.btn {
							position: absolute;
							width: 80px;
							height: 80px;
							// background: #000;
							border-radius: 50%;
							background: rgba(255, 255, 255, 0.5);
							border: 15px solid rgba(0, 0, 0, 0.5);
							bottom: 50px;
							
							font-weight: 500;
							font-size: 40px;
							color: #000;
						}
						.btn1 {
							left: 50px;
						}
						.btn2 {
							right: 50px;
						}
					}
				}
			}
			.box4 {
				width: 100%;
				height: 206px;
				margin-top: 130px;
				padding: 0 180px;
				box-sizing: border-box;
			}
			.box5 {
				background: #fff;
				width: 100%;
				margin-top: 130px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box5-content {
					width: 100%;
					height: 1800px;
					margin-top: 40px;
					padding: 0 180px;
					box-sizing: border-box;
					background: url(../image/decoration/coll_box5_bg.jpg);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					.list1 {
						padding: 0 60px 80px 60px;
						box-sizing: border-box;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
                        align-items: center;
						margin-top: 50px;
						background: #fff;
						width: 100%;
						height: 480px;
						background: #ffffff;
						border-radius: 0px 0px 30px 30px;
						.item {
							width: 270px;
							height: 120px;
							background: #ffffff;
							border: 1px solid #e7e7e7;
							margin-bottom: 20px;
						}
					}
					.btn {
						width: 652px;
						height: 120px;
						background: #753cbe;
						border-radius: 60px;
						margin-top: 40px;
						
						font-weight: bold;
						font-size: 54px;
						color: #ffffff;
						z-index: 9;
					}
					.list2 {
						padding: 100px 30px 0 30px;
						box-sizing: border-box;
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;
						margin-top: -60px;
						background: #fff;
						width: 100%;
						height: 790px;
						background: #ffffff;
						border-radius: 30px;
						.list-item {
							width: 348px;
							height: 305px;
							border-radius: 15px;
							background: #ffffff;
							margin-bottom: 45px;
							.img-box {
								width: 100%;
								height: 270px;
								position: relative;
                                border-radius: 15px;
                                box-shadow: 0px 3px 6px 0px rgba(4, 0, 0, 0.4);
								.icon {
									position: absolute;
									left: 0;
									top: 0;
									width: 140px;
									height: 140px;
								}
								.seq {
									position: absolute;
									left: 5px;
									top: 5px;
									font-family: DIN;
									font-weight: bold;
									font-size: 30px;
									color: #ffffff;
								}
								.img {
									width: 100%;
									height: 270px;
									object-fit: cover;
									border-radius: 15px;
								}
							}

							.text3 {
								width: 234px;
								height: 70px;
								background: #ffffff;
								border-radius: 35px;
								opacity: 0.98;
								
								font-weight: 500;
								font-size: 40px;
								color: #120523;
								margin-top: -35px;
								box-shadow: 0px 3px 6px 0px rgba(4, 0, 0, 0.04);
							}
						}
					}
				}
			}
		}
	}
}
</style>
